import React from 'react'
import { get } from 'lodash'
import SVG from 'react-inlinesvg'
import PropTypes from 'prop-types'
import LinkHandler from 'components/atoms/LinkHandler'
import { startsWithHttp, toUpperCase } from 'helpers/strings'
import IconSmile from 'assets/img/icon-smile.svg'

import './CardIcon.scss'

function CardIcon(props) {
  const id = get(props, 'id')
  const title = get(props, 'title')
  const url = get(props, 'url')
  const active = get(props, 'active')
  const footer = get(props, 'footer')
  const icon = get(props, 'icon')

  return (
    <LinkHandler id={id} to={url} title={toUpperCase(title)}>
      <div className={'max-line-2 cardIcon card-icon' + (active ? ' active' : '')}>
        <SVG
          className="icon"
          src={icon || IconSmile}
          preProcessor={(code) => code.replace(/filter=".*?"/g, '')}
          width="100%"
          height="100%"
        />
        <p className={'card-icon-text' + (footer ? ' py-3' : '') + (!footer ? ' pt-3' : '')}>{title}</p>
      </div>
    </LinkHandler>
  )
}

CardIcon.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  active: PropTypes.bool,
  footer: PropTypes.bool,
  icon: PropTypes.string,
}

export default CardIcon
