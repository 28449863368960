import React from 'react'
import CardIcon from 'components/molecules/CardIcon'
import get from 'lodash.get'

// Style
import './AccessMenuDesktop.scss'

function AccessMenuDesktop(props) {
  return (
    <section className="d-none d-lg-block">
      <div className="AccessMenuDesktop__big-nav-desktop">
        <p>Acesse sua área</p>
      </div>
      <nav id={props.id} aria-label="Menu de acesso" className="AccessMenuDesktop__fast-menu">
        {Array.isArray(props.links) &&
          props.links.map((item, index) => (
            <CardIcon
              id={get(item, 'id')}
              key={index}
              title={get(item, 'label')}
              url={get(item, 'link.url.path', '')}
              footer={get(item, 'footer')}
              active={get(item, 'active') || index === 0}
              icon={get(item, 'media.entity.fieldMediaFile.entity.url')}
            />
          ))}
      </nav>
    </section>
  )
}

export default AccessMenuDesktop
