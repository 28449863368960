import React, { useEffect } from 'react'
import Container from 'react-bootstrap/Container'
import AccessMenuDesktop from 'components/organisms/AccessMenuDesktop'
import AccessMenuMobile from 'components/organisms/AccessMenuMobile'
import { useDispatch, useSelector } from 'react-redux'
import * as customPageActions from 'store/modules/customPage/actions'
import isEqual from 'lodash.isequal'
import get from 'lodash.get'

function AccessMenu(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!menus.menuQuery) {
      dispatch(customPageActions.getAccessMenus({ public: true }))
    }
  }, [])

  let menus = useSelector((state) => state.customPage.accessMenus, isEqual)
  let id = get(props, 'data.id')
  let subId = get(menus, 'menuQuery.entity.id')
  let accessMenus = get(menus, 'menuQuery.entity.links')

  return (
    <Container id={id} fluid="fluid" className="bg-primary">
      <Container>
        <AccessMenuDesktop id={subId} links={accessMenus} />
        <AccessMenuMobile id={subId} links={accessMenus} />
      </Container>
    </Container>
  )
}

export default AccessMenu
