import React, {useState} from 'react'
import {HashLink} from 'react-router-hash-link'

import './AccessMenuMobile.scss'

function AccessMenuMobile(props) {
  const [openMenu, setOpenMenu] = useState(false)

  return (
    <section className="AccessMenuMobile d-block d-lg-none fast-menu">
      <HashLink to="/app" className="cta" onClick={() => setOpenMenu(!openMenu)}>
        Acesse sua área
        <i className="icone-chevron-right" />
      </HashLink>
    </section>
  )
}

export default AccessMenuMobile